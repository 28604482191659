import { Box, FormControl, InputLabel } from '@mui/material';
import useListParams from 'hooks/useListParams';
import { useCallback } from 'react';
import Select from 'components/common/Select';
import { getReferencesOptions } from '../../api';
import { useSuspenseQuery } from '@tanstack/react-query';

const FilterLevel = () => {
  const { setLevel } = useListParams();

  const { data: levels } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.levels.map(({ name, id }) => ({ value: id, label: name })),
  });

  const handleChange = useCallback(
    (value: string[]) => {
      setLevel(value);
    },
    [setLevel]
  );

  return (
    <Box sx={{ display: 'flex', maxWidth: 200, minWidth: 108, flex: 1 }}>
      <FormControl sx={{ display: 'flex', flexGrow: 1 }}>
        <InputLabel size="small" id="filter-level">
          Level
        </InputLabel>
        <Select
          name="level"
          inputLabel="Level"
          size="small"
          multiple
          required
          selectAll={false}
          options={levels}
          handleChange={handleChange}
        />
      </FormControl>
    </Box>
  );
};

export default FilterLevel;
