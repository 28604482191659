import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, styled } from '@mui/material';
import { useModal } from 'hooks';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

export const ConditionVariantOnTaskStyled = styled(Box)(({ theme: t }) => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  border: `1px solid ${t.palette.divider}`,
  borderRadius: t.borderRadius.md,
  minHeight: 36,
  maxHeight: 36,
  alignItems: 'stretch',
}));

export const ConditionVariantOnTaskItemStyled = styled(Box)<{ divider?: boolean }>(({ theme: t, divider }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  borderRight: divider ? `1px solid ${t.palette.divider}` : 'none',
}));

// eslint-disable-next-line react/display-name
export const MoreAboutVariantsDialog: FC = memo(() => {
  const { isOpen, toggle } = useModal();
  const handleToggle = useCallback(() => {
    toggle();
  }, [toggle]);
  return (
    <>
      <Button
        size="small"
        variant="text"
        disableElevation
        sx={{
          display: 'inline-flex',
          textTransform: 'none',
          py: 0,
          fontWeight: 400,
          '&:hover': { backgroundColor: 'transparent' },
        }}
        onClick={handleToggle}
      >
        More about variants
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { maxWidth: 440, minHeight: 180, width: '90%', py: 1 } }}
      >
        <DialogTitle id="alert-dialog-title" component={'h4'} sx={{ textAlign: 'center', lineHeight: 2, mb: -1 }}>
          More about Variants
        </DialogTitle>
        <DialogContent sx={{ pb: 1 }}>
          <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
            <ListItem sx={{ display: 'list-item' }}>
              Variant allows to set up different values for Actions depending on values in Conditions.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>Variant creates a copy of task with different values. </ListItem>
            <ListItem sx={{ display: 'list-item' }}>All conditions from the original task are inherited.</ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              To set up Variant, you need to choose Conditions that would have different values only.
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
          <Button onClick={handleToggle}>Back to task</Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
