import type { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTriggerRule } from '../../api/triggerRule';

interface TriggerRuleModalProps {
  isOpen: boolean;
  ruleId: number;
  ruleName: string;
  handleClose: () => void;
}

const TriggerRuleModal: FC<TriggerRuleModalProps> = ({ isOpen, ruleId, ruleName, handleClose }) => {
  const { mutate: trigger, isPending } = useTriggerRule({
    network: 'facebook',
    config: {
      onSettled: () => {
        handleClose();
      },
    },
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle id="alert-dialog-title" align="center" sx={{ fontWeight: 500 }}>
        Run rule manually?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" align="center">
          {ruleName}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', px: 2.5, pb: 2.5 }}>
        <Button sx={{ textTransform: 'capitalize' }} disableElevation onClick={handleClose} disabled={isPending}>
          Cancel
        </Button>
        <LoadingButton
          disableElevation
          loading={isPending}
          onClick={() => {
            trigger({ ruleId });
          }}
          autoFocus
          variant="contained"
          color="primary"
          sx={{ textTransform: 'capitalize' }}
        >
          Run rule
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TriggerRuleModal;
