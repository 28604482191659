import type { QueryKey } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { MetricEntry } from './types';

const getConditionsConstants = async ({ signal }: { signal?: AbortSignal }): Promise<MetricEntry[]> => {
  const res = await apiClient.get<{ data: MetricEntry[] }>(`/conditions/constants`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data.data;
};

interface UseConditionsConstants<TData> {
  config?: QueryConfig<typeof getConditionsConstants, TData>;
}

export const useConditionsConstants = <TData = MetricEntry[]>({ config }: UseConditionsConstants<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getConditionsConstants({ signal }),
    queryKey: ['conditions', 'constants'] as QueryKey,
  });
};
