import { useCurrentNetwork } from 'hooks';
import facebookTasksFormStore from '../facebook/stores/tasksFormStore';
import googleTasksFormStore from '../google/store/tasksFormStore';
import type { Network } from 'types/shared';
import type { TasksFormStoreInterface } from '../types/taskFormStore.types';
import { useRef } from 'react';

const useCurrentTasksFormStore = (): TasksFormStoreInterface => {
  const tasksFormStoreRef = useRef<Record<Network, TasksFormStoreInterface>>({
    facebook: facebookTasksFormStore,
    google: googleTasksFormStore,
  });

  const network = useCurrentNetwork();

  return tasksFormStoreRef.current[network];
};

export default useCurrentTasksFormStore;
