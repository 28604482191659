import { isUndefined } from 'lodash-es';
import { FLOW_CONSTANT_METRICS, REPLACE_ACTIONS, REST_ACTIONS, SINGLE_ACTIONS, STRING_ACTIONS } from '../constants';
import type { ComparisonCondition, RuleAction, RuleTaskEntry, SingleCondition } from '../types';

const isValidActionFields = (item?: RuleAction): boolean => {
  const isSingleAction = SINGLE_ACTIONS.includes(item?.action!);
  if (isSingleAction) return true;

  const isStringAction = STRING_ACTIONS.includes(item?.action!);
  if (isStringAction) return !!item?.value;

  const isReplaceAction = REPLACE_ACTIONS.includes(item?.action!);
  if (isReplaceAction) return !!item?.value && !!item?.second_value;

  const isRestAction = REST_ACTIONS.includes(item?.action!);
  if (isRestAction) return !!item?.value;

  const isActionValid = !!item?.action && !!item?.value;
  const subAction = item?.sub_actions?.[0];
  if (subAction) {
    const isSubActionsValid = !!subAction?.action && !!subAction?.value && !!item?.count;
    return isActionValid && isSubActionsValid;
  }

  return isActionValid;
};

const isValidConditionFields = (condition: Partial<ComparisonCondition & SingleCondition>): boolean => {
  const isComparisonCondition =
    Object.hasOwn(condition, 'second_metric') &&
    Object.hasOwn(condition, 'second_metric_level') &&
    Object.hasOwn(condition, 'second_period');
  const isConstantNameSingleCondition = FLOW_CONSTANT_METRICS.includes(condition?.metric!);
  const isConstantNameComparisonCondition = FLOW_CONSTANT_METRICS.includes(condition?.second_metric!);

  if (isConstantNameSingleCondition && !isComparisonCondition) return !!condition?.constant_name;

  const isValidSingleCondition =
    !!condition?.metric && !!condition?.metric_level && !!condition?.period && !!condition?.operator;

  const isValidComparisonCondition =
    !!condition?.second_metric && !!condition?.second_metric_level && !!condition?.second_period;

  if (isComparisonCondition) {
    if (isConstantNameSingleCondition && isConstantNameComparisonCondition)
      return !!condition?.constant_name && !!condition?.second_metric_constant_name;

    if (isConstantNameSingleCondition) return !!condition?.constant_name && isValidComparisonCondition;

    if (isConstantNameComparisonCondition) return !!condition?.second_metric_constant_name && isValidSingleCondition;

    return isValidSingleCondition && isValidComparisonCondition;
  }

  return isValidSingleCondition;
};

const tasksFormValidation = ({ tasksForm, indexTask }: { tasksForm: RuleTaskEntry[]; indexTask?: number }) => {
  indexTask = isUndefined(indexTask) ? tasksForm.length - 1 : indexTask; // if the index of the current task is not received, then we can agree that the last task to be checked

  const currentTask = tasksForm[indexTask]; // Get the current task from the tasks form store based on the indexTask
  const currentActions = currentTask?.actions ?? []; // Get the actions associated with the current task, default to an empty array if actions are null or undefined
  const listConditions = tasksForm[indexTask]?.condition?.conditions; // Get the conditions associated with the current task, default to an empty array if conditions are null or undefined
  const lastCondition = listConditions?.at(-1) ?? {}; // Get the last condition from the list of conditions, default to an empty object if the list is empty
  const lastAction = currentActions?.at(-1); // Get the last action from the list of actions, default to undefined if the list is empty

  const hasMandatoryConditionValues = isValidConditionFields(lastCondition); // Check if the last condition has all mandatory values

  const hasActionsMandatoryValues = isValidActionFields(lastAction); // Check if the last action has all mandatory values

  const isAddConditionAvailable = !listConditions?.length || hasMandatoryConditionValues; // Determine if adding a new condition is available

  const isAddActionAvailable = !currentActions?.length || hasActionsMandatoryValues; // Determine if adding a new action is available

  const isAddTaskAvailable = !tasksForm.length || (hasActionsMandatoryValues && hasMandatoryConditionValues); // Determine if adding a new task is available

  return {
    isAddConditionAvailable,
    isAddTaskAvailable,
    isAddActionAvailable,
  };
};

export default tasksFormValidation;
