import { DUPLICATE_ACTIONS, REPLACE_ACTIONS, REST_ACTIONS, SINGLE_ACTIONS, STRING_ACTIONS } from '../constants';

const actionFieldsVisibility = (storedActionValue: string) => {
  // const storedActionValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant })?.action;

  return {
    isCountVisible: DUPLICATE_ACTIONS.includes(storedActionValue),
    isSubActionVisible: DUPLICATE_ACTIONS.includes(storedActionValue) && !SINGLE_ACTIONS.includes(storedActionValue),
    isValueVisible: !SINGLE_ACTIONS.includes(storedActionValue) && !REPLACE_ACTIONS.includes(storedActionValue),
    isValueToggleVisible: REST_ACTIONS.includes(storedActionValue) && !REPLACE_ACTIONS.includes(storedActionValue),
    isOnlyStringValue: STRING_ACTIONS.includes(storedActionValue) || DUPLICATE_ACTIONS.includes(storedActionValue),
    isSubValueToggleVisible: REST_ACTIONS.includes(storedActionValue),
    isUnitVisible: REST_ACTIONS.includes(storedActionValue),
    isReplaceInName: REPLACE_ACTIONS.includes(storedActionValue),
    isSingleActionValue: SINGLE_ACTIONS.includes(storedActionValue),
  };
};

export default actionFieldsVisibility;
