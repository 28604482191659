/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { cloneDeep, isEmpty, isUndefined, omit, pickBy } from 'lodash-es';
import {
  FLOW_CONSTANT_METRICS,
  REPLACE_ACTIONS,
  REST_ACTIONS,
  SINGLE_ACTIONS,
  STRING_ACTIONS,
  SUB_ACTIONS,
} from '../../constants';
import type {
  ActionIndexProps,
  MetricField,
  RuleAction,
  RuleActionField,
  RuleSubActionEntry,
  RuleSubActionField,
  RuleTaskEntry,
} from '../../types';
import { makeAutoObservable, toJS, transaction } from 'mobx';
import type { TextFieldProps } from '@mui/material';
import type { TasksFormStoreInterface } from '../../types/taskFormStore.types';

class TasksFormStore implements TasksFormStoreInterface {
  tasksForm: RuleTaskEntry[] = [];
  private singleCondition = {
    type: 'condition',
    metric: '',
    constant_name: '',
    period: '',
    operator: 'GREATER_THAN',
    metric_level: '',
    metric_multiplier: '1',
    critical_value: '1',
  };
  private comparisonCondition = {
    type: 'condition',
    metric: '',
    period: '',
    operator: 'GREATER_THAN',
    metric_level: '',
    metric_multiplier: '1',
    critical_value: '1',
    second_metric: '',
    second_period: '',
    second_metric_level: '',
    second_metric_multiplier: '1',
    second_metric_constant_name: '',
  };
  private taskModelEntry = {
    actions: [],
    condition: {
      type: 'group',
      operator: 'AND', // AND || OR
      conditions: [],
    },
    description: '',
    __variantsOnTask: [],
    __selectedDependenciesOnConditions: [],
  };
  private actionModelEntry = {
    count: null,
    value: '',
    action: '',
    sub_actions: [{ value: '', action: '' }],
    second_value: null,
    unit: 'absolute', // absolute || metric
  };
  private __propsRestriction: TextFieldProps = {
    disabled: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  get propsRestriction() {
    return this.__propsRestriction;
  }

  get tasksFormValues() {
    let variantsOnTask: RuleTaskEntry[] = [];

    const r = cloneDeep(this.tasksForm).map((task) => {
      const variants: RuleTaskEntry[] =
        task.__variantsOnTask?.map(
          (task: RuleTaskEntry) =>
            omit(toJS(task), ['__variantsOnTask', '__selectedDependenciesOnConditions']) as RuleTaskEntry
        ) ?? [];

      variantsOnTask = [...variantsOnTask, ...variants];
      return omit(toJS(task), ['__variantsOnTask', '__selectedDependenciesOnConditions']) as RuleTaskEntry;
    });

    const tasks = toJS([...r, ...variantsOnTask]).map((t) => {
      return {
        ...t,

        // Parse sub actions, remove empty fields
        actions: t.actions.map((action) => {
          const currentAction: RuleAction = { ...action };

          if (Array.isArray(action.sub_actions)) {
            currentAction.sub_actions = action.sub_actions
              .map((subAction) => {
                return Object.fromEntries(
                  Object.entries(subAction).filter(([, value]) => !isEmpty(value))
                ) as RuleSubActionEntry;
              })
              .filter((subAction) => Object.keys(subAction).length > 0);
          }
          return currentAction;
        }),

        condition: {
          ...t.condition,

          // Remove empty fields (null, undefined)
          conditions: t.condition.conditions.map((condition) =>
            pickBy({ ...condition }, (value) => !isEmpty(value?.toString()))
          ),
        },
      };
    });

    return tasks;
  }

  initialTasks(tasks: RuleTaskEntry[]) {
    transaction(() => {
      this.tasksForm = tasks;
    });
  }

  reset() {
    this.tasksForm = [];
  }

  addTask() {
    transaction(() => {
      this.tasksForm.push({ ...this.taskModelEntry });
    });
  }

  getTask(indexTask: number) {
    return this.tasksForm[indexTask];
  }

  getTasks() {
    return this.tasksForm;
  }

  deleteTask(index: number) {
    const memoryState = cloneDeep(this.tasksForm);
    if (this.tasksForm.length > 1) {
      this.tasksForm.splice(index, 1);
    } else {
      this.tasksForm = [];
    }

    return {
      undo: () => {
        this.tasksForm = [...memoryState];
      },
    };
  }

  duplicateTask(index: number) {
    transaction(() => {
      const duplicatedTask = cloneDeep(this.tasksForm[index]);
      this.tasksForm.splice(index, 0, duplicatedTask);
    });
  }

  // Variants
  getVariantsOnTask(indexTask: number) {
    return this.tasksForm[indexTask].__variantsOnTask;
  }

  getVariantOnTask(indexTask: number, indexVariant: number) {
    return this.tasksForm[indexTask].__variantsOnTask[indexVariant];
  }

  getVariantOnTaskCondition(indexTask: number, indexVariant: number, indexCondition: number) {
    return this.tasksForm[indexTask].__variantsOnTask[indexVariant].condition.conditions[indexCondition];
  }

  getVariantOnTaskActionValues(indexTask: number, indexVariant: number, indexAction: number) {
    return this.tasksForm[indexTask].__variantsOnTask[indexVariant].actions[indexAction] as Record<
      RuleActionField,
      string | number | null
    >;
  }

  _createVariant(ruleTask: RuleTaskEntry): RuleTaskEntry {
    const taskCopy = { ...ruleTask };

    return {
      actions: taskCopy.actions,
      condition: {
        ...taskCopy.condition,
        conditions: taskCopy.condition.conditions,
        operator: taskCopy.condition.operator ?? '',
        type: taskCopy.condition.type ?? '',
      },
      description: ruleTask.description,
      __selectedDependenciesOnConditions: ruleTask.__selectedDependenciesOnConditions ?? [],
      __variantsOnTask: [],
    };
  }

  addVariantOnTask(indexTask: number) {
    transaction(() => {
      if (this.tasksForm[indexTask]?.__variantsOnTask?.length) {
        const currentVariantTask = (this.tasksForm[indexTask].__variantsOnTask.at(-1) ?? {}) as RuleTaskEntry;
        this.tasksForm[indexTask].__variantsOnTask.push(cloneDeep(currentVariantTask));
      } else {
        this.tasksForm[indexTask].__variantsOnTask = [
          {
            ...cloneDeep(this.tasksForm[indexTask]),
            __selectedDependenciesOnConditions: [],
            __variantsOnTask: [],
          },
        ];
      }
    });
  }

  deleteVariantOnTask(indexTask: number, indexVariant: number) {
    const memoryState = cloneDeep(this.tasksForm[indexTask].__variantsOnTask);
    transaction(() => {
      this.tasksForm[indexTask].__variantsOnTask.splice(indexVariant, 1);
    });

    return {
      undo: () => {
        this.tasksForm[indexTask].__variantsOnTask = [...memoryState];
      },
    };
  }

  setSelectedDependenciesOnConditions(indexTask: number, indexVariant: number, value: number[]) {
    transaction(() => {
      this.tasksForm[indexTask].__variantsOnTask[indexVariant].__selectedDependenciesOnConditions = value;
    });
  }

  changeVariantTaskConditionOperator(
    indexTask: number,
    indexVariant: number,
    indexCondition: number,
    operator: string
  ) {
    transaction(() => {
      this.tasksForm[indexTask].__variantsOnTask[indexVariant].condition.conditions[indexCondition].operator = operator;
    });
  }

  setVariantOnTaskConditionMetric(
    indexTask: number,
    indexVariant: number,
    indexCondition: number,
    field: MetricField,
    value: string
  ) {
    const condition = this.tasksForm[indexTask].__variantsOnTask[indexVariant].condition.conditions[indexCondition];
    condition ? ((condition as Record<MetricField, any>)[field as MetricField] = value) : null;
  }

  addSingleCondition(index: number) {
    this.tasksForm[index].condition.conditions.push({ ...this.singleCondition });
  }

  addComparisonCondition(index: number) {
    this.tasksForm[index].condition.conditions.push({ ...this.comparisonCondition });
  }

  deleteCondition(indexTask: number, indexCondition: number) {
    const memoryState = cloneDeep(this.tasksForm[indexTask]?.condition.conditions);
    this.tasksForm[indexTask].condition.conditions.splice(indexCondition, 1);
    return {
      undo: () => {
        this.tasksForm[indexTask].condition.conditions = [...memoryState];
      },
    };
  }

  getConditionMetric(indexTask: number, indexCondition: number) {
    return this.tasksForm[indexTask]?.condition.conditions[indexCondition];
  }

  setConditionMetric(indexTask: number, indexCondition: number, field: MetricField, value: string) {
    transaction(() => {
      const resetMetricFields = () => {
        condition.metric_level = '';
        condition.period = '';
        condition.constant_name = '';
      };

      const resetSecondMetricFields = () => {
        condition.second_metric_level = '';
        condition.second_period = '';
        condition.second_metric_constant_name = '';
      };

      const condition = this.tasksForm[indexTask]?.condition.conditions[indexCondition];
      condition[field as MetricField] = value;

      const isFlowConstantValue = FLOW_CONSTANT_METRICS.includes(value);

      if (field === 'metric') {
        isFlowConstantValue ? resetMetricFields() : (condition.constant_name = '');
      }

      if (field === 'second_metric') {
        isFlowConstantValue ? resetSecondMetricFields() : (condition.second_metric_constant_name = '');
      }
    });
  }

  changeOperator(index: number, operator: string) {
    transaction(() => {
      this.tasksForm[index] = {
        ...this.tasksForm[index],
        condition: {
          ...this.tasksForm[index].condition,
          operator,
        },
      };
    });
  }

  getOperator(index: number) {
    console.log(this.tasksForm);
    return this.tasksForm[index].condition.operator;
  }

  setActionFieldValue({
    indexTask,
    indexAction,
    indexVariant,
    field,
    value,
  }: {
    indexTask: number;
    indexAction: number;
    indexVariant?: number;
    field: RuleActionField;
    value: string;
  }) {
    transaction(() => {
      const task = this.tasksForm[indexTask];
      if (
        field === 'action' &&
        [...SINGLE_ACTIONS, ...REPLACE_ACTIONS, ...STRING_ACTIONS, ...REST_ACTIONS].includes(value)
      ) {
        if (isUndefined(indexVariant)) {
          task.actions[indexAction].value = '';
          task.actions[indexAction].second_value = '';
          task.actions[indexAction].count = '';
          task.actions[indexAction].sub_actions = [{ value: '', action: '' }];
        } else {
          task.__variantsOnTask[indexVariant].actions[indexAction].value = '';
        }

        if (!SUB_ACTIONS.includes(value)) {
          task.actions[indexAction].sub_actions = [{ value: '', action: '' }];
        }
      }

      const action = isUndefined(indexVariant)
        ? task?.actions[indexAction]
        : task.__variantsOnTask[indexVariant]?.actions[indexAction];

      action ? ((action as Record<RuleActionField, any>)[field as RuleActionField] = value) : null;
    });
  }

  setSubActionField({
    indexTask,
    indexAction,
    indexVariant,
    field,
    value,
  }: {
    indexTask: number;
    indexAction: number;
    indexVariant?: number;
    field: RuleSubActionField;
    value: string;
  }) {
    transaction(() => {
      const action = this.tasksForm[indexTask].actions[indexAction];
      const subActions = action?.sub_actions;
      if (field === 'action') {
        if (isUndefined(indexVariant)) {
          if (!!action && isEmpty(subActions)) {
            action.sub_actions = [
              {
                action: '',
                value: '',
              },
            ];
          } else if (!isEmpty(subActions)) {
            subActions[0].value = '';
          }
        } else {
          const subActions = this.tasksForm[indexTask].__variantsOnTask[indexVariant].actions[indexAction].sub_actions;
          subActions[0].value = '';
        }
      }

      const subAction = isUndefined(indexVariant)
        ? this.tasksForm[indexTask]?.actions[indexAction]?.sub_actions?.[0]
        : this.tasksForm[indexTask].__variantsOnTask[indexVariant].actions[indexAction]?.sub_actions?.[0];

      subAction ? ((subAction as Record<RuleSubActionField, any>)[field] = value) : null;
    });
  }

  getActionValues({ indexAction, indexTask, indexVariant }: ActionIndexProps) {
    const task = isUndefined(indexVariant)
      ? this.tasksForm[indexTask]
      : this.tasksForm[indexTask].__variantsOnTask[indexVariant];
    return task.actions[indexAction] as Record<RuleActionField, string | number | null>;
  }

  getSubActionValues({ indexAction, indexTask, indexVariant }: ActionIndexProps) {
    const task = isUndefined(indexVariant)
      ? this.tasksForm[indexTask]
      : this.tasksForm[indexTask].__variantsOnTask[indexVariant];

    const subActions = !isEmpty(task.actions[indexAction]?.sub_actions)
      ? task.actions[indexAction].sub_actions
      : [{ value: '', action: '' }];
    return subActions[0] as Record<RuleSubActionField, string>;
  }

  addAction({
    indexTask,
    indexVariant,
    action,
  }: Pick<ActionIndexProps, 'indexTask' | 'indexVariant'> & { action: string }) {
    transaction(() => {
      const task = isUndefined(indexVariant)
        ? this.tasksForm[indexTask]
        : this.tasksForm[indexTask].__variantsOnTask[indexVariant];

      // replacing actions object so references in props would be updated properly and can be used as dependencies for hooks
      task.actions = [...task.actions, { ...this.actionModelEntry, action, sub_actions: [{ value: '', action: '' }] }];
    });
  }

  deleteAction({ indexTask, indexAction, indexVariant }: ActionIndexProps) {
    let memoryState: RuleAction[] = [];

    transaction(() => {
      if (isUndefined(indexVariant)) {
        memoryState = cloneDeep(this.tasksForm[indexTask].actions);
        const actionsClone = cloneDeep(this.tasksForm[indexTask].actions);
        actionsClone.splice(indexAction, 1);

        this.tasksForm[indexTask].actions = actionsClone;
      } else {
        memoryState = cloneDeep(this.tasksForm[indexTask].__variantsOnTask[indexVariant].actions);
        const actionsClone = cloneDeep(this.tasksForm[indexTask].__variantsOnTask[indexVariant].actions);
        actionsClone.splice(indexAction, 1);
        this.tasksForm[indexTask].__variantsOnTask[indexVariant].actions = actionsClone;
      }
    });

    return {
      undo: ({ indexTask, indexVariant }: Pick<ActionIndexProps, 'indexTask' | 'indexVariant'>) => {
        if (isUndefined(indexVariant)) {
          this.tasksForm[indexTask].actions = [...memoryState];
        } else {
          this.tasksForm[indexTask].__variantsOnTask[indexVariant].actions = [...memoryState];
        }
      },
    };
  }
}

const tasksFormStore = new TasksFormStore();

export default tasksFormStore;
