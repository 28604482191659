import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import { Suspense, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TaskAltOutlined as TaskAltOutlinedIcon } from '@mui/icons-material';
import {
  AddActionsButton,
  AddConditionButton,
  AddTaskButton,
  ConditionCriticalValueField,
  ConditionFormContainer,
  ConditionFormContainerTitle,
  ConditionMetrics,
  ConditionMultiplayer,
  ConstantsMetrics,
  DeleteTaskButton,
  DuplicateTaskButton,
  FlowsMetrics,
  LabelFormSection,
  LevelsMetrics,
  PeriodsMetrics,
  TaskConditionOperator,
  TaskOperator,
  VerticalDivider,
} from './index';

import {
  ActionCountValueField,
  ActionFormContainerTitle,
  ActionMetrics,
  ActionReplaceNameFields,
  ActionTypeFieldSelect,
  ActionUnitValue,
  ActionValue,
  ActionsType,
  SubActionValueField,
  SuspenseFallbackSkeleton,
} from './RuleEditTasksFormComponents';
import type {
  RuleTaskCondition,
  RuleTaskEntry,
  MetricField,
  ConditionIndexProps,
  RuleAction,
  ActionIndexProps,
  ActionFieldsOptions,
  TaskIndexProps,
} from '../types';
import RuleEditVariantsOnTask from './RuleEditVariantsOnTask';
import actionFieldsVisibility from '../utils/actionFieldsVisibility';
import useCurrentTasksFormStore from '../hooks/useCurrentTasksFormStore';

const ConditionForm: FC<
  ConditionIndexProps & {
    title: string;
    field: MetricField;
    isSecond?: boolean; // for second metric condition
  }
> = observer(({ title, indexCondition, indexTask, field, isSecond }) => {
  const tasksFormStore = useCurrentTasksFormStore();

  const fieldValue = tasksFormStore.getConditionMetric(indexTask, indexCondition)?.[field];
  const isConstantField = ['constant', 'flow'].includes(fieldValue as string);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Box sx={{ flex: 1, flexDirection: 'column' }}>
          <Box sx={{ flex: 1, height: 24, maxHeight: 24, pl: 1.2 }}>
            <Typography variant="body2" component="span" sx={(t) => ({ color: t.palette.primary.main })}>
              {title}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Multiplier" placement="top" arrow>
              <Box
                sx={(t) => ({
                  flex: '0 0 62px',
                  border: `1px solid ${t.palette.divider}`,
                  borderRight: 'none',
                  borderRadius: `${t.borderRadius.md} 0 0 ${t.borderRadius.md}`,
                  height: 36,
                  maxHeight: 36,
                  width: '100%',
                })}
              >
                <ConditionMultiplayer
                  indexCondition={indexCondition}
                  indexTask={indexTask}
                  field={isSecond ? 'second_metric_multiplier' : 'metric_multiplier'}
                />
              </Box>
            </Tooltip>
            <Box
              sx={(t) => ({
                flex: 1,
                border: `1px solid ${t.palette.divider}`,
                borderRight: isConstantField ? 'none' : '',
                height: 36,
                maxHeight: 36,
              })}
            >
              <Suspense fallback={<SuspenseFallbackSkeleton />}>
                <ConditionMetrics indexCondition={indexCondition} indexTask={indexTask} field={field} />
              </Suspense>
            </Box>
          </Box>
        </Box>
        {isConstantField ? (
          <Box sx={{ flex: 1, flexDirection: 'column' }}>
            <Box sx={{ flex: 1, height: 24, maxHeight: 24, pl: 2 }}>
              <Typography
                variant="body2"
                component="span"
                sx={(t) => ({ textTransform: 'capitalize', color: t.palette.text.secondary })}
              >
                Constant Name
              </Typography>
            </Box>
            <Box
              sx={(t) => ({
                flex: 1,
                border: `1px solid ${t.palette.divider}`,
                borderRadius: `0 ${t.borderRadius.md} ${t.borderRadius.md} 0`,
                height: 36,
              })}
            >
              <Suspense fallback={<SuspenseFallbackSkeleton />}>
                <>
                  {fieldValue === 'constant' && (
                    <ConstantsMetrics
                      indexCondition={indexCondition}
                      indexTask={indexTask}
                      field={isSecond ? 'second_metric_constant_name' : 'constant_name'}
                    />
                  )}
                  {fieldValue === 'flow' && (
                    <FlowsMetrics
                      indexCondition={indexCondition}
                      indexTask={indexTask}
                      field={isSecond ? 'second_metric_constant_name' : 'constant_name'}
                    />
                  )}
                </>
              </Suspense>
            </Box>
          </Box>
        ) : null}
        {!isConstantField ? (
          <>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <Box sx={{ flex: 1, height: 24, maxHeight: 24, pl: 2 }}>
                <Typography
                  variant="body2"
                  component="span"
                  sx={(t) => ({ textTransform: 'capitalize', color: t.palette.text.secondary })}
                >
                  Level
                </Typography>
              </Box>
              <Box
                sx={(t) => ({
                  flex: 1,
                  borderTop: `1px solid ${t.palette.divider}`,
                  borderBottom: `1px solid ${t.palette.divider}`,
                  height: 36,
                  maxHeight: 36,
                })}
              >
                <Suspense fallback={<SuspenseFallbackSkeleton />}>
                  <LevelsMetrics
                    indexCondition={indexCondition}
                    indexTask={indexTask}
                    field={isSecond ? 'second_metric_level' : 'metric_level'}
                  />
                </Suspense>
              </Box>
            </Box>
            <Box sx={{ flex: 1, flexDirection: 'column' }}>
              <Box sx={{ flex: 1, height: 24, maxHeight: 24, pl: 2 }}>
                <Typography
                  variant="body2"
                  component="span"
                  sx={(t) => ({ textTransform: 'capitalize', color: t.palette.text.secondary })}
                >
                  Period
                </Typography>
              </Box>
              <Box
                sx={(t) => ({
                  flex: 1,
                  border: `1px solid ${t.palette.divider}`,
                  borderRadius: `0 ${t.borderRadius.md} ${t.borderRadius.md} 0`,
                  height: 36,
                  maxHeight: 36,
                })}
              >
                <Suspense fallback={<SuspenseFallbackSkeleton />}>
                  <PeriodsMetrics
                    indexCondition={indexCondition}
                    indexTask={indexTask}
                    field={isSecond ? 'second_period' : 'period'}
                  />
                </Suspense>
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
});

const ComparisonConditionForm: FC<ConditionIndexProps> = ({ indexCondition, indexTask }) => {
  return (
    <ConditionFormContainer
      indexCondition={indexCondition}
      title={
        <ConditionFormContainerTitle
          title={`Condition ${indexCondition + 1}`}
          subTitle={`Metric compared to metric`}
          indexCondition={indexCondition}
          indexTask={indexTask}
        />
      }
    >
      <ConditionForm title="1st metric" indexCondition={indexCondition} indexTask={indexTask} field="metric" />

      <TaskConditionOperator indexCondition={indexCondition} indexTask={indexTask} />

      <ConditionForm
        title="2st metric"
        indexCondition={indexCondition}
        indexTask={indexTask}
        field="second_metric"
        isSecond
      />
    </ConditionFormContainer>
  );
};

const SingleConditionForm: FC<ConditionIndexProps> = ({ indexCondition, indexTask }) => {
  return (
    <ConditionFormContainer
      indexCondition={indexCondition}
      title={
        <ConditionFormContainerTitle
          title={`Condition ${indexCondition + 1}`}
          subTitle={`Single metric`}
          indexCondition={indexCondition}
          indexTask={indexTask}
        />
      }
    >
      <ConditionForm title="Metric" indexCondition={indexCondition} indexTask={indexTask} field="metric" />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 3, width: '100%' }}>
        <TaskConditionOperator indexCondition={indexCondition} indexTask={indexTask} isSingleCondition />
        <ConditionCriticalValueField indexCondition={indexCondition} indexTask={indexTask} />
      </Box>
    </ConditionFormContainer>
  );
};

const ConditionsGroup: FC<{ condition: RuleTaskCondition; index: number }> = observer(
  ({ condition, index: indexTask }) => {
    return (
      <Box sx={{ display: 'flex', flex: 1, gap: 3, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: 'start' }}>
          <Typography variant="h4" sx={{ fontWeight: 500 }}>
            Condition group
          </Typography>
          <TaskOperator indexTask={indexTask} />
        </Box>
        {condition.conditions.map((item, indexCondition) => {
          const isComparisonCondition = Object.hasOwn(item, 'metric') && Object.hasOwn(item, 'second_metric');
          return isComparisonCondition ? (
            <ComparisonConditionForm
              key={`TaskCondition:Comparison:${item.metric}.${item.second_metric}.${item.operator}.${item.type}:${indexCondition}`}
              indexCondition={indexCondition}
              indexTask={indexTask}
            />
          ) : (
            <SingleConditionForm
              key={`TaskCondition:Single:${item.metric}.${item.operator}.${item.type}:${indexCondition}`}
              indexCondition={indexCondition}
              indexTask={indexTask}
            />
          );
        })}
        <Box>
          <AddConditionButton indexTask={indexTask} />
        </Box>
      </Box>
    );
  }
);

const ActionEntry: FC<ActionIndexProps> = observer(({ indexTask, indexVariant, indexAction }) => {
  const tasksFormStore = useCurrentTasksFormStore();
  const storedActionValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant })?.action;
  const {
    isValueVisible,
    isOnlyStringValue,
    isReplaceInName,
    isSubActionVisible,
    isValueToggleVisible,
    isSingleActionValue,
  } = actionFieldsVisibility(storedActionValue as string);
  const storedValue = tasksFormStore.getActionValues({ indexTask, indexAction, indexVariant }).value;

  const [actionFieldsOptions, setActionFieldsOptions] = useState<ActionFieldsOptions>(() =>
    typeof storedValue === 'string' && isNaN(Number(storedValue)) && !storedValue.includes('{{') ? 'metric' : 'absolute'
  );

  const handleIsMetricValueField = useCallback(
    (value: ActionFieldsOptions) => {
      tasksFormStore.setActionFieldValue({
        indexTask,
        indexAction,
        indexVariant,
        field: 'unit',
        value: 'absolute',
      });
      tasksFormStore.setActionFieldValue({
        indexTask,
        indexAction,
        indexVariant,
        field: 'value',
        value: '',
      });

      setActionFieldsOptions(value);
    },
    [tasksFormStore, indexVariant, indexTask, indexAction, setActionFieldsOptions]
  );

  return (
    <ConditionFormContainer
      type="action"
      indexCondition={indexAction}
      title={
        <ActionFormContainerTitle
          title={`Action ${indexAction + 1}`}
          indexAction={indexAction}
          indexTask={indexTask}
          indexVariant={indexVariant}
        />
      }
      sx={{ p: 0 }}
    >
      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              flex: isReplaceInName ? '0 0 40%' : isSingleActionValue ? 1 : '0 0 40%',
              borderBottom: isSubActionVisible ? `1px solid ${`rgba(0, 8, 46, 0.12)`}` : 'none',
              borderRight: isValueVisible ? `1px solid ${`rgba(0, 8, 46, 0.12)`}` : 'none',
            }}
          >
            <Suspense fallback={<SuspenseFallbackSkeleton />}>
              <ActionsType
                indexAction={indexAction}
                indexTask={indexTask}
                indexVariant={indexVariant}
                field={'action'}
              />
            </Suspense>
          </Box>
          {isValueVisible && (
            <>
              {!isOnlyStringValue && isValueToggleVisible && (
                <Box
                  sx={{
                    flex: '0 0 80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderBottom: isSubActionVisible ? `1px solid ${`rgba(0, 8, 46, 0.12)`}` : 'none',
                    borderRight: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,
                  }}
                >
                  <ActionTypeFieldSelect onChange={handleIsMetricValueField} value={actionFieldsOptions} />
                </Box>
              )}
              <Box sx={{ flex: 1, borderBottom: isSubActionVisible ? `1px solid ${`rgba(0, 8, 46, 0.12)`}` : 'none' }}>
                {actionFieldsOptions === 'metric' && !isOnlyStringValue ? (
                  <Suspense fallback={<SuspenseFallbackSkeleton />}>
                    <ActionMetrics
                      indexAction={indexAction}
                      indexTask={indexTask}
                      indexVariant={indexVariant}
                      field="value"
                    />
                  </Suspense>
                ) : (
                  <ActionValue
                    indexAction={indexAction}
                    indexTask={indexTask}
                    indexVariant={indexVariant}
                    field="value"
                    type="string"
                  />
                )}
              </Box>
              {actionFieldsOptions === 'absolute' && (
                <ActionUnitValue indexAction={indexAction} indexTask={indexTask} indexVariant={indexVariant} />
              )}
            </>
          )}
          {!isValueVisible && isReplaceInName && (
            <ActionReplaceNameFields indexAction={indexAction} indexTask={indexTask} indexVariant={indexVariant} />
          )}
        </Box>

        <ActionCountValueField indexAction={indexAction} indexTask={indexTask} indexVariant={indexVariant} />
        <SubActionValueField indexAction={indexAction} indexTask={indexTask} indexVariant={indexVariant} />
      </Box>
    </ConditionFormContainer>
  );
});

export const ActionsGroup: FC<{ actions: RuleAction[]; indexTask: number; indexVariant?: number }> = observer(
  ({ actions, indexTask, indexVariant }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          maxWidth: '40%',
          gap: 3,
          flexDirection: 'column',
          position: 'relative',
          pl: 4,
          pt: 1,
        }}
      >
        <VerticalDivider />
        <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', gap: 3 }}>
          <Typography variant="h4" sx={{ fontWeight: 500, mb: 0.75 }}>
            Actions
          </Typography>

          {actions.map((_, indexAction) => (
            <ActionEntry
              key={`ActionGroupEntry.task:${indexTask}.action:${indexAction}.variant:${indexVariant}`}
              indexTask={indexTask}
              indexAction={indexAction}
              indexVariant={indexVariant}
            />
          ))}
        </Box>
        <Box>
          <AddActionsButton actions={actions} indexTask={indexTask} indexVariant={indexVariant} />
        </Box>
      </Box>
    );
  }
);

const TaskItem: FC<TaskIndexProps & { task: RuleTaskEntry }> = observer(({ task, indexTask }) => {
  return (
    <Box
      id={`task-item-${indexTask}`}
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 3,
        mb: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          '.visibleByHover': { visibility: 'hidden' },
          '&:hover .visibleByHover': { visibility: 'visible' },
          '&:last-of-type .taskGroupsContainer': { borderBottom: 'none' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 62,
            borderBottom: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,

            background: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 4,
          }}
        >
          <LabelFormSection label={`Task ${indexTask + 1}`} icon={<TaskAltOutlinedIcon />} />
          <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start', gap: 1.5 }}>
            <DuplicateTaskButton indexTask={indexTask} />
            <DeleteTaskButton indexTask={indexTask} />
          </Box>
        </Box>
        <Box
          className={'taskGroupsContainer'}
          sx={{ display: 'flex', width: '100%', py: 3, gap: 3, borderBottom: `1px solid ${`rgba(0, 8, 46, 0.12)`}` }}
        >
          <ConditionsGroup condition={task.condition} index={indexTask} />
          <ActionsGroup actions={task.actions} indexTask={indexTask} />
        </Box>
      </Box>
      <Box>
        <RuleEditVariantsOnTask indexTask={indexTask} />
      </Box>
    </Box>
  );
});

const TaskItemSkeleton: FC = () => {
  return (
    <Box
      id={`TaskItemSkeleton:task-item`}
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 3,
        mb: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          '.visibleByHover': { visibility: 'hidden' },
          '&:hover .visibleByHover': { visibility: 'visible' },
          '&:last-of-type .taskGroupsContainer': { borderBottom: 'none' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 62,
            borderBottom: `1px solid ${`rgba(0, 8, 46, 0.12)`}`,

            background: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 4,
          }}
        >
          <Skeleton width={180} height={32} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
          <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start', gap: 1.5 }}>
            <Skeleton width={32} height={32} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
            <Skeleton width={32} height={32} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
          </Box>
        </Box>
        <Box
          className={'taskGroupsContainer'}
          sx={{ display: 'flex', width: '100%', py: 3, gap: 3, borderBottom: `1px solid ${`rgba(0, 8, 46, 0.12)`}` }}
        >
          <Skeleton width={'100%'} height={32} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
        </Box>
      </Box>
    </Box>
  );
};

const RuleEditTasksForm: FC = observer(() => {
  const tasksFormStore = useCurrentTasksFormStore();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'start' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Tasks
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 4,
          width: '100%',
          flexDirection: 'column',
        }}
      >
        {tasksFormStore.tasksForm.map((task: RuleTaskEntry, i: number) => (
          <Suspense key={`TaskItem:${i}`} fallback={<TaskItemSkeleton />}>
            <TaskItem task={task} indexTask={i} />
          </Suspense>
        ))}
      </Box>
      <Box sx={{ display: 'flex', width: '100%', borderBottom: `1px solid ${`rgba(0, 8, 46, 0.12)`}`, py: 2 }}>
        <AddTaskButton />
      </Box>
    </Box>
  );
});

export default RuleEditTasksForm;
