import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  Add as AddIcon,
  SubdirectoryArrowRightOutlined as SubdirectoryArrowIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material';
import type { RuleTaskConditionEntry, TaskIndexProps, VariantsOnTaskIndexProps } from '../types';
import {
  AddVariantOnTask,
  ConditionVariantForm,
  ConditionVariantsContainerStyled,
  DeleteVariantOnTask,
  DependingAutoCompleteStyled,
  VariantOnTaskConditionMultiplayer,
  VariantOnTaskCriticalValueField,
  VariantTaskConditionOperator,
} from './index';
import { FormProvider, useForm } from 'react-hook-form';
import { variantsOnTaskSchema } from '../validation/variantsOnTask.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import tasksFormValidation from '../utils/tasksFormValidation';
import {
  ConditionVariantOnTaskItemStyled,
  ConditionVariantOnTaskStyled,
  MoreAboutVariantsDialog,
} from './RuleEditVariantsOnTaskComponents';
import { ActionsGroup } from './RuleEditTasksForm';
import { FLOW_CONSTANT_METRICS } from '../constants';
import { isEmpty } from 'lodash-es';
import useCurrentTasksFormStore from '../hooks/useCurrentTasksFormStore';

const DependingAutocomplete: FC<VariantsOnTaskIndexProps> = observer(({ indexTask, indexVariant }) => {
  const tasksFormStore = useCurrentTasksFormStore();

  const variantsOnTaskValue = tasksFormStore.getVariantsOnTask(indexTask);
  const variantsConditions = variantsOnTaskValue[indexVariant].condition.conditions;

  const conditions =
    variantsConditions
      .filter((condition) => !!condition.metric)
      .map((condition, index) => ({
        value: index,
        label: `Condition ${index + 1} (metric: ${condition.metric} ${condition.second_metric ? `, second_metric: ${condition.second_metric}` : ''})`,
      })) ?? [];

  return (
    <Box sx={{ display: 'flex', width: 'fit-content', minWidth: 420, maxWidth: '100%' }}>
      <DependingAutoCompleteStyled
        allSelectable={false}
        name={`dependingOnConditions`}
        id={`DependingAutocomplete-${indexTask}`}
        options={conditions ?? []}
        InputProps={{ label: 'Depending on' }}
        onChange={(value) => {
          tasksFormStore.setSelectedDependenciesOnConditions(indexTask, indexVariant, value as number[]);
        }}
      />
    </Box>
  );
});

const VariantsEmptyState: FC<TaskIndexProps & { handleAddVariants: () => void }> = observer(
  ({ handleAddVariants, indexTask }) => {
    const tasksFormStore = useCurrentTasksFormStore();
    const { isAddTaskAvailable } = tasksFormValidation({ tasksForm: tasksFormStore.tasksForm, indexTask });
    return (
      <Box
        sx={(t) => ({
          display: 'flex',
          gap: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
          width: '100%',
          border: `1px dashed ${isAddTaskAvailable ? t.palette.primary.main : t.palette.grey[400]}`,
          borderRadius: t.borderRadius.md,
        })}
      >
        <Button startIcon={<AddIcon />} variant="text" onClick={handleAddVariants} disabled={!isAddTaskAvailable}>
          Add variant
        </Button>
        <Typography
          variant="body2"
          sx={(t) => ({ color: isAddTaskAvailable ? t.palette.text.secondary : t.palette.grey[400] })}
        >
          Set up actions depending on condition’s value
        </Typography>
      </Box>
    );
  }
);

const SingleConditionVariantOnTaskFields: FC<
  VariantsOnTaskIndexProps & { indexCondition: number; condition: RuleTaskConditionEntry }
> = ({ condition, indexTask, indexVariant, indexCondition }) => {
  return (
    <>
      <ConditionVariantOnTaskStyled>
        <ConditionVariantOnTaskItemStyled divider sx={{ flex: '0 0 64px' }}>
          <VariantOnTaskConditionMultiplayer
            indexTask={indexTask}
            indexVariant={indexVariant}
            indexCondition={indexCondition}
            field="metric_multiplier"
          />
        </ConditionVariantOnTaskItemStyled>
        <ConditionVariantOnTaskItemStyled divider sx={{ flex: '0 0 180px' }}>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {condition.metric}
          </Typography>
        </ConditionVariantOnTaskItemStyled>
        <ConditionVariantOnTaskItemStyled divider>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {condition.metric_level}
          </Typography>
        </ConditionVariantOnTaskItemStyled>
        <ConditionVariantOnTaskItemStyled>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {condition.period}
          </Typography>
        </ConditionVariantOnTaskItemStyled>
      </ConditionVariantOnTaskStyled>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, width: '100%' }}>
        <Box>
          <VariantTaskConditionOperator
            indexTask={indexTask}
            indexVariant={indexVariant}
            indexCondition={indexCondition}
            isSingleCondition
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <VariantOnTaskCriticalValueField
            indexTask={indexTask}
            indexVariant={indexVariant}
            indexCondition={indexCondition}
          />
        </Box>
      </Box>
    </>
  );
};

const ComparisonConditionVariantOnTaskFields: FC<
  VariantsOnTaskIndexProps & { indexCondition: number; condition: RuleTaskConditionEntry }
> = ({ condition, indexTask, indexVariant, indexCondition }) => {
  return (
    <>
      <ConditionVariantOnTaskStyled>
        <ConditionVariantOnTaskItemStyled divider sx={{ flex: '0 0 64px' }}>
          <VariantOnTaskConditionMultiplayer
            indexTask={indexTask}
            indexVariant={indexVariant}
            indexCondition={indexCondition}
            field="metric_multiplier"
          />
        </ConditionVariantOnTaskItemStyled>
        <ConditionVariantOnTaskItemStyled divider sx={{ flex: '0 0 180px' }}>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {condition.metric}
          </Typography>
        </ConditionVariantOnTaskItemStyled>
        {FLOW_CONSTANT_METRICS.includes(condition.metric!) ? (
          <ConditionVariantOnTaskItemStyled divider sx={{ flex: 1 }}>
            <Typography
              variant="body2"
              sx={(t) => ({
                color: t.palette.text.secondary,
                textOverflow: 'ellipsis',
                display: 'inline-block',
                maxWidth: 100,
                maxHeight: 28,
                overflow: 'hidden',
                lineHeight: 1,
              })}
            >
              {condition.constant_name}
            </Typography>
          </ConditionVariantOnTaskItemStyled>
        ) : null}
        <ConditionVariantOnTaskItemStyled divider>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {condition.metric_level}
          </Typography>
        </ConditionVariantOnTaskItemStyled>
        <ConditionVariantOnTaskItemStyled>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            {condition.period}
          </Typography>
        </ConditionVariantOnTaskItemStyled>
      </ConditionVariantOnTaskStyled>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1 }}>
        <VariantTaskConditionOperator
          indexTask={indexTask}
          indexVariant={indexVariant}
          indexCondition={indexCondition}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', width: '100%' }}>
        <ConditionVariantOnTaskStyled>
          <ConditionVariantOnTaskItemStyled divider sx={{ flex: '0 0 64px' }}>
            <VariantOnTaskConditionMultiplayer
              indexTask={indexTask}
              indexVariant={indexVariant}
              indexCondition={indexCondition}
              field="second_metric_multiplier"
            />
          </ConditionVariantOnTaskItemStyled>
          <ConditionVariantOnTaskItemStyled divider sx={{ flex: '0 0 180px' }}>
            <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
              {condition.second_metric}
            </Typography>
          </ConditionVariantOnTaskItemStyled>
          {FLOW_CONSTANT_METRICS.includes(condition.second_metric!) ? (
            <ConditionVariantOnTaskItemStyled divider sx={{ flex: 1 }}>
              <Typography
                variant="body2"
                sx={(t) => ({
                  color: t.palette.text.secondary,
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  maxWidth: 100,
                  maxHeight: 28,
                  overflow: 'hidden',
                  lineHeight: 1,
                })}
              >
                {condition.second_metric_constant_name}
              </Typography>
            </ConditionVariantOnTaskItemStyled>
          ) : null}
          <ConditionVariantOnTaskItemStyled divider>
            <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
              {condition.second_metric_level}
            </Typography>
          </ConditionVariantOnTaskItemStyled>
          <ConditionVariantOnTaskItemStyled>
            <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
              {condition.second_period}
            </Typography>
          </ConditionVariantOnTaskItemStyled>
        </ConditionVariantOnTaskStyled>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1, width: '100%' }}>
          <Box sx={{ position: 'relative', flex: 1, display: 'flex', width: '100%' }}>
            <VariantOnTaskCriticalValueField
              indexTask={indexTask}
              indexVariant={indexVariant}
              indexCondition={indexCondition}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

const VariantsOnTask: FC<VariantsOnTaskIndexProps> = observer(({ indexTask, indexVariant }) => {
  const tasksFormStore = useCurrentTasksFormStore();

  const variantsOnTaskList = tasksFormStore.getVariantsOnTask(indexTask);

  const formMethods = useForm({
    resolver: yupResolver(variantsOnTaskSchema),
    defaultValues: {
      dependingOnConditions: variantsOnTaskList[indexVariant].__selectedDependenciesOnConditions ?? [],
    },
  });

  const { watch } = formMethods;
  const dependingOnConditions = watch('dependingOnConditions');

  const actions = variantsOnTaskList[indexVariant].actions;

  return (
    <FormProvider {...formMethods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 2,
          mb: 4,
          '.visiblyDeleteButton': { visibility: 'hidden' },
          '&:hover .visiblyDeleteButton': { visibility: 'visible' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
            <Box
              sx={(t) => ({
                flex: 1,
                maxHeight: 36,
                minHeight: 36,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 1,
                width: '100%',
                backgroundColor: 'rgba(41, 121, 255, 0.04)',
                borderRadius: t.borderRadius.md,
              })}
            >
              <Typography
                variant="subtitle2"
                sx={(t) => ({
                  display: 'inline-flex',
                  whiteSpace: 'nowrap',
                  color: t.palette.primary.main,
                  gap: 0.5,
                })}
              >
                <SubdirectoryArrowIcon />
                Variant {indexVariant + 1}
              </Typography>
              <DeleteVariantOnTask indexTask={indexTask} indexVariant={indexVariant} />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%', gap: 1 }}>
              <DependingAutocomplete indexTask={indexTask} indexVariant={indexVariant} />

              {!dependingOnConditions?.length && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  <Box>
                    <InfoIcon sx={(t) => ({ fontSize: 19, color: t.palette.grey[600] })} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="caption" component={'span'}>
                      Select conditions to set up variant.
                    </Typography>
                    <Box sx={{ mt: -0.25 }}>
                      <MoreAboutVariantsDialog />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {dependingOnConditions?.length ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <Box sx={{ display: 'flex', flex: 1, gap: 1, flexDirection: 'column' }}>
              <ConditionVariantsContainerStyled>
                {(dependingOnConditions as number[]).map((indexCondition) => {
                  const condition = variantsOnTaskList[indexVariant].condition.conditions[indexCondition];
                  const isComparisonCondition =
                    Object.hasOwn(condition, 'metric') && Object.hasOwn(condition, 'second_metric');

                  return (
                    <Box
                      key={`ConditionVariants.Condition:${indexCondition}.Variant:${indexVariant}`}
                      sx={(t) => ({
                        px: 2,
                        pt: 1.5,
                        pb: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        '&:not(:last-child)': {
                          borderBottom: `1px solid ${t.palette.divider}`,
                        },
                      })}
                    >
                      {isComparisonCondition ? (
                        <ConditionVariantForm
                          key={`Task:${indexTask}.Variant:${indexVariant}.Condition:${indexCondition}`}
                          title={`Condition ${indexCondition + 1}`}
                        >
                          <ComparisonConditionVariantOnTaskFields
                            indexTask={indexTask}
                            indexCondition={indexCondition}
                            indexVariant={indexVariant}
                            condition={condition}
                          />
                        </ConditionVariantForm>
                      ) : (
                        <ConditionVariantForm
                          key={`Task:${indexTask}.Variant:${indexVariant}.Condition:${indexCondition}`}
                          title={`Condition ${indexCondition + 1}`}
                        >
                          <SingleConditionVariantOnTaskFields
                            indexTask={indexTask}
                            indexCondition={indexCondition}
                            indexVariant={indexVariant}
                            condition={condition}
                          />
                        </ConditionVariantForm>
                      )}
                    </Box>
                  );
                })}
              </ConditionVariantsContainerStyled>
            </Box>

            <ActionsGroup actions={actions} indexTask={indexTask} indexVariant={indexVariant} />
          </Box>
        ) : null}
      </Box>
    </FormProvider>
  );
});

const RuleEditVariantsOnTask: FC<TaskIndexProps> = observer(({ indexTask }) => {
  const tasksFormStore = useCurrentTasksFormStore();

  const variantsOnTask = tasksFormStore.getVariantsOnTask(indexTask);
  const isVariants = !isEmpty(variantsOnTask);

  const handleAddVariantOnTask = () => {
    tasksFormStore.addVariantOnTask(indexTask);
  };

  return isVariants ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography component="span" sx={(t) => ({ typography: t.typography.h4, display: 'inline-flex', gap: 0.5 })}>
        Variants of Task {indexTask + 1}
      </Typography>
      {variantsOnTask.map((item, indexVariant) => (
        <VariantsOnTask
          key={`Task:${indexTask}.Variant:${indexVariant}.Dependencies:${item.__selectedDependenciesOnConditions.join(',')}`}
          indexTask={indexTask}
          indexVariant={indexVariant}
        />
      ))}
      <Box>
        <AddVariantOnTask onClick={handleAddVariantOnTask} indexTask={indexTask} />
      </Box>
    </Box>
  ) : (
    <VariantsEmptyState handleAddVariants={handleAddVariantOnTask} indexTask={indexTask} />
  );
});

export default RuleEditVariantsOnTask;
